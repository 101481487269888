/* global $, quickverleih, showAlert, openPopup, openPopupOverPopup, closePopup, setDataTablesOptions, datePicker */

$(document).ready(function () {

    /*
    *	New Customer popup
    */
    $(document).on('click', '#new_customer', function () {
        quickverleih.customer.add_new_customer();
    });



    /*
    * Save Customer by jquery.form
    */
    $(document).on('click', '#save_customer', function () {

        if ($(this).hasClass('submit-button')) { // this means the save button is active

            // validate number and password
            var customerId = $('#customerId').val();
            var customerPass = getCustomerPass();
            var errors = false;

            if (customerPass === false) {
                $('#customerPass, #customerPassConfirm').addClass('input-error');
                alert("Die eingegebenen Passwörter stimmen nicht überein!");
                errors = true;
            }

            // ..........................
            // checks all required fields
            // ..........................
            $(this).parent().find('[required]').each(function (idex, elem) {
                var tempVal = $(elem).val();
                //console.log('tempVal: '+tempVal);
                if (typeof tempVal === 'undefined' || tempVal == '' || ($(elem).attr('name') === 'tenant_id' && tempVal == 0 /* for select tenant elements */)) {
                    errors = true;
                }
            });
            // ..........................

            // ..........................
            // show some error message
            // ..........................
            if (errors) {
                var message = 'Bitte alle benötigten Felder ausfüllen.';
                showAlert(message);
            }
            // ..........................

            if (errors === false) {
                var options = {
                    success: function (data) {
                        //console.log(data);
                        if (isNaN(data) && typeof data === 'string' && data.indexOf('existing username') >= 0) {
                            errors = true;
                            if (errors) {
                                var message = 'Dieser Benutzername ist schon vergeben.';
                                showAlert(message);
                                return false;
                            }
                        }

                        // disable save button after customer save
                        $('#save_customer').removeClass('submit-button');
                        $('#save_customer').addClass('submit-button-disabled');

                        // if this edit was called from the contract, then go back to adding the customer to the contract
                        if($('#save_customer').data('edit_from_contract') === 1 && quickverleih.contract.callback_after_edit !== null) {
                            quickverleih.contract.callback_after_edit(customerId);
                        } else {
                            // get data and repopulate table
                            quickverleih.customers.customerTableReload();
                        }

                        closePopup();
                    },
                    error: function(/* data */) {
                        //console.log(data.toSource());
                    },
                    url: 'includes/kunden/ajax/ajaxCustomer.php',
                    data: {
                        method: 'saveCustomer'
                    },
                    dataType: 'json'
                };

                $('#customerForm').ajaxSubmit(options);
            }

        }


    });

    /*
    * Edit Customer
    */

    $(document).on('click', '.edit-customer', function () {
        var customerId = $(this).attr('id-customer');

        quickverleih.customer.edit_customer(customerId);
    });

    /*
    * Add New Note
    */
    $(document).on('click', '#new_customer_note', function () {

        var randomId = Math.floor((Math.random() * 999999) + 100000); 	// generate a random id

        var noteHtml = '<tr>';
        noteHtml += '<td>';
        noteHtml += '	<input type="hidden" id="customerNoteId" name="customerNoteId[]" value="">';
        noteHtml += '	<div id="datepickerNote_' + randomId + '_wrap" class="datepicker-wrap datepicker-toggle"></div>';
        noteHtml += '</td>';
        noteHtml += '<td>';
        noteHtml += '	<select id="customerNoteArt" name="customerNoteArt[]" style="width: 100%">';
        noteHtml += '		<option value="Telefonisch">Telefonisch</option>';
        noteHtml += '		<option value="Persönlich">Persönlich</option>';
        noteHtml += '		<option value="eMail">eMail</option>';
        noteHtml += '		<option value="Post">Post</option>';
        noteHtml += '		<option value="Fax">Fax</option>';
        noteHtml += '		<option value="Schriftlich">Schriftlich</option>';
        noteHtml += '	</select>';
        noteHtml += '</td>';
        noteHtml += '<td></td>';
        noteHtml += '<td>';
        noteHtml += '	<input type="text" id="customerNoteContent" value="" name="customerNoteContent[]" style="width: 95%">';
        noteHtml += '	<button type="button" class="reset-button x-button delete-customer-note-NEW">';
        noteHtml += '      <svg class="icon" style="width: 13px; height: 13px;">';
        noteHtml += '        <use xlink:href="#icon-x"></use>';
        noteHtml += '      </svg>';
        noteHtml += '   </button>';
        noteHtml += '</td>';
        noteHtml += '</tr>"';

        $('#CustomerNotesTableBody').append(noteHtml);
        datePicker.add("datepickerNote_" + randomId + "_wrap", '', '', '');

    });

    /*
    * Delete Customer
    */
    $(document).on('click', '#delete_customer', function () {

        if ($(this).hasClass('delete-button')) {
            var customerId = $('#customerId').val();
            var customerName = $('#customerPopupTitle').html().trim();

            var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
            data += 'Möchten Sie diesen Ausleiher löschen?';
            data += '</div></div>';
            data += '<div class="popup-content">';
            data += '<br><p><b>' + customerName + '</b></p><br>';
            data += '<div class="button delete-button delete-customer-yes" data-id="' + customerId + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
            data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-customer-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

            openPopupOverPopup(data, '500px');
        }

    });

    $(document).on('click', '.delete-customer-yes', function () {

        var customerId = $(this).attr('data-id');

        if (customerId > 0) {	// delete from DB
            $.ajax({
                url: 'includes/kunden/ajax/ajaxCustomer.php',
                type: 'POST',
                /* async: false,*/
                data: {
                    customerId: customerId,
                    method: 'deleteCustomer'
                },
                success: function (data) {
                    // get data and repopulate table
                    quickverleih.customers.customerTableReload();
                    closePopup();

                    var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
                    html += 'Bestätigung Kunde löschen';
                    html += '</div></div>';
                    html += '<div class="popup-content">';
                    html += '<br><p><b>' + data + '</b></p><br>';
                    openPopup(html, '500px');
                }
            });
        } else {
            //
        }

    });


    $(document).on('click', '.delete-customer-note', function () {

        var customerNoteId = $(this).attr('id-customer-note');
        var customerNoteContent = $(this).parent().parent().find('#customerNoteContent').val();

        var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
        data += 'Möchten Sie die Notiz zu diesem Ausleiher löschen?';
        data += '</div></div>';
        data += '<div class="popup-content">';
        data += '<br><p><b>' + customerNoteContent + '</b></p><br>';
        data += '<div class="button delete-button delete-customer-note-yes" data-id="' + customerNoteId + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
        data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-customer-note-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

        openPopupOverPopup(data, '500px');

    });

    $(document).on('click', '.delete-customer-note-yes', function () {

        var customerNoteId = $(this).attr('data-id');
        var customerId = $('#customerId').val();

        if (customerId > 0) {	// delete from DB
            $.ajax({
                url: 'includes/kunden/ajax/ajaxCustomer.php',
                type: 'POST',
                /* async: false,*/
                data: {
                    customerNoteId: customerNoteId,
                    customerId: customerId,
                    method: 'deleteCustomerNote'
                },
                success: function (data) {
                    // confirmation
                    var title = 'Ausleihernotiz löschen';
                    var content = '<br><p><b>' + data + '</b></p><br>';
                    $('#popup1 .message-header-title').html(title);
                    $('#popup1 .popup-content').html(content);
                }
            });

            // remove note from DOM
            $('#customerNoteId_' + customerNoteId).parent().parent().remove();

        } else {
            //remove note from DOM
            $(this).parent().parent().remove();
        }
    });

    /* delete not saved note */
    $(document).on('click', '.delete-customer-note-NEW', function () {
        $(this).parent().parent().remove();
    });


    /* Open New Ausleiher popup if we have NEU param in URL */
    if ($('#new_customer_param').val() == 1) {
        $('#new_customer').click();
    }

    /*
    * Change Tenant
    */
    // $('#tenantId').change(function() {
    $(document).on('change', '#tenantId', function () {
        let newTenant = $('#tenantId').val();

        quickverleih.customer.customerLocationsSelectReload(newTenant);
        quickverleih.customer.reloadCustomerSpecialisations(newTenant);
    })


});

/*
* Customer functions
*/

/*
* checks if the pass and pass confirmation match
*/
function getCustomerPass() {

    var customerPass = $('#customerPass').val();
    var customerPassConfirm = $('#customerPassConfirm').val();

    if (customerPass == customerPassConfirm) {
        return customerPass;
    } else {
        return false;
    }

}

$(document).on('ready', function () {
    quickverleih.customers.addCustomerFilterFunctionality();
});

quickverleih.customers = (function () {
    let filterValue = "";

    /* Customer Table Reload */
    var customerTableReload = () => {

        $.ajax({
            url: 'includes/kunden/ajax/CustomersTableRowsView.php?' + "&customer_filter=" + filterValue,
            type: 'GET',
            // data: {},
            success: function (data) {

                let $customerTable = $('#customerTable');
                var dataTablesOptions = setDataTablesOptions($customerTable.parents('.data-table-block'));

                $customerTable.DataTable().destroy();
                $customerTable.find('tbody').html(data);
                $customerTable.DataTable(dataTablesOptions);

                addCustomerFilterFunctionality();
            }
        });
    }

    var addCustomerFilterFunctionality = () => {

        let filterInput = $('#customerTable_filter').find('input');
        filterInput.val(filterValue);

        filterInput.on('keypress', function (e) {

            let key = e.which || e.keyCode;
            if (key === 13) {
                filterValue = this.value;
                $('#CustomersTableWrapper').addClass('loading');
    
                $.ajax({
                    type: "GET",
                    url: "includes/kunden/ajax/CustomersTableRowsView.php?" + "&customer_filter=" + filterValue,
                    success: function (data) {
    
                        let table = $('#customerTable').DataTable();
    
                        table.clear();
    
                        $(data).each(function () {
    
                            if ($(this).is('tr')) {
    
                                table.row.add($(this));
                            }
                        });
    
                        table.draw();
    
                        setTimeout(function () {
    
                            $('#CustomersTableWrapper').removeClass('loading');
                        }, 200);
                    }
                });
            }
        });
    }
    
    return {
        customerTableReload: customerTableReload,
        addCustomerFilterFunctionality: addCustomerFilterFunctionality
    };
})();

quickverleih.customer = (function () {
    this.customerId = 0;

    var check_ausleiher_contract = function (customerId) {
        this.customerId = customerId;

        $.ajax({
            url: 'includes/kunden/ajax/ajaxCustomer.php',
            type: 'POST',
            data: {
                customerId: customerId,
                method: 'checkCustomerInRes'
            },
            success: function (data) {
                let check = data.replace(/\s/g, '');
                if (check !== '1') {
                    $('#delete_customer').removeClass('delete-button').addClass('submit-button-disabled').attr('disabled', 'disabled');
                    $('#delete_customer').attr('title', 'Dieser Ausleiher hat mindestens eine Reservierung oder Anfrage und kann nicht gelöscht werden.');
                }
            }
        });
    }

    var add_new_customer = function () {
        $.ajax({
            url: 'includes/kunden/ajax/ajaxCustomer.php',
            type: 'POST',
            data: {
                method: 'createNewCustomer'
            },
            success: function (data) {
                openPopup(data, '1200px');

                init_warehouse_locations_select();
            }
        });
    }

    var edit_customer = function (customerId) {
        this.customerId = customerId;

        $.ajax({
            url: 'includes/kunden/ajax/ajaxCustomer.php',
            type: 'POST',
            data: {
                customerId: customerId,
                method: 'editCustomer'
            },
            success: function (data) {
                openPopup(data, '1200px');

                init_warehouse_locations_select();

                // disable save button after customer load
                $('#save_customer').removeClass('submit-button');
                $('#save_customer').addClass('submit-button-disabled');

                check_ausleiher_contract(customerId);
            }
        });
    }

    var init_warehouse_locations_select = function () {
        $('.select2.multiple-checkboxes').select2MultiCheckboxes({
            templateSelection: function (selected/*, total */) {
                if (selected == 0) {
                    return "Bitte auswählen"
                }
                return selected.length + " ausgewählt";
            },
            allowClear: false
        });
        $('.select2.multiple-checkboxes').off('select2:unselecting').on('select2:unselecting', function (e) {
            if(e.params && e.params.args && e.params.args.data && e.params.args.data.element) {
                let $option = $(e.params.args.data.element);
                if($option.data('disabled')) {
                    e.preventDefault();
                    return;
                }
            }
        });
    }

    /* Customer Locations reload */
    var customerLocationsSelectReload = function (tenant_id) {

        $.ajax({
            url: 'includes/kunden/ajax/CustomerLocationsMultiSelect.php',
            type: 'POST',
            data: {
                "customer_id": this.customerId,
                "tenant_id": tenant_id
            },
            success: function (data) {

                let $warehouseLocationsSelect = $('#warehouse_locations_row');

                $warehouseLocationsSelect.replaceWith(data);
                init_warehouse_locations_select();
            }
        });
    }

    var reloadCustomerSpecialisations = function(tenant_id) {

        $.ajax({
            url: 'includes/kunden/ajax/CustomerSpecialisations.php',
            type: 'POST',
            data: {
                "customer_id": this.customerId,
                "tenant_id": tenant_id
            },
            success: function (html) {
                let $studentSpecialisationSelect = $('#studentSpecialisation');

                $studentSpecialisationSelect.replaceWith(html);
            }
        });
    }

    return {
        add_new_customer,
        edit_customer: edit_customer,
        customerLocationsSelectReload: customerLocationsSelectReload,
        reloadCustomerSpecialisations: reloadCustomerSpecialisations
    };
})();
